import { lazy } from 'react'

const BASE_URL = process.env.PUBLIC_URL || '/'

const ROUTES = [
  {
    id: 'home',
    path: BASE_URL + 'home',
    defaultPath: BASE_URL + 'home',
    exact: true,
    component: lazy(() => import('../containers/pages/Home'))
  },
  {
    id: 'help',
    path: BASE_URL + 'help',
    defaultPath: BASE_URL + 'help',
    exact: true,
    component: lazy(() => import('../containers/pages/Help'))
  },
  {
    id: 'registration',
    path: BASE_URL + 'registration',
    defaultPath: BASE_URL + 'registration',
    exact: true,
    component: lazy(() => import('../containers/pages/Registration'))
  },
  {
    id: 'faq',
    path: BASE_URL + 'faq',
    defaultPath: BASE_URL + 'faq',
    exact: true,
    component: lazy(() => import('../containers/pages/FAQ'))
  }
]

export default ROUTES
