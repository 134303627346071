import React, { memo, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { matchPath, useHistory } from 'react-router'
import ROUTES from 'config/routes'
import styled from 'styled-components'
import LocalizedStrings from 'react-localization'
import l10nResources from './header.l10n'
import { Layout, Logo, Menu } from '@apple/ui-lib2'
import { APP_TITLE } from 'config/settings'

const { Header } = Layout
const l10n = new LocalizedStrings(l10nResources)

function AppHeader() {
  const history = useHistory()
  const { pathname } = useLocation()
  const currentPage = Object.values(ROUTES).find((route) =>
    matchPath(pathname, { path: route.path, exact: false })
  )

  const menuItems = useMemo(
    () => [
      { label: l10n.links.home, key: 'home', onClick: () => history.push(`/home`) },
      { label: l10n.links.faq, key: 'faq', onClick: () => history.push(`/faq`) },
      { label: l10n.links.help, key: 'help', onClick: () => history.push(`/help`) }
    ],
    [history]
  )

  return (
    <StyledHeader>
      <StyledLogo onClick={() => history.push('/home')}>
        <Logo title={APP_TITLE} />
      </StyledLogo>

      <Menu
        mode="horizontal"
        items={menuItems}
        selectedKeys={[currentPage?.id || 'home']}
        style={{
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: 'calc(100% - 400px)'
        }}
      />
    </StyledHeader>
  )
}

const StyledHeader = styled(Header)`
  position: fixed;
  width: 100%;
  z-index: 2;
`

const StyledLogo = styled.div`
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  div > div {
    padding-bottom: 2px;
    margin-right: 8px;
  }
`

export default memo(AppHeader)
