import React, { Suspense, memo } from 'react'
import Header from 'components/Header'
import styled from 'styled-components'
import ROUTES from 'config/routes'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Layout, LoadingSpin } from '@apple/ui-lib2'

import '@apple/ui-lib2/dist/theme/ui.css'
import './app.css'

function App() {
  return (
    <BrowserRouter>
      <StyledLayout>
        <Header />
        <Suspense fallback={<LoadingSpin />}>
          <Switch>
            {ROUTES.map(({ component: Component, ...route }, index) => (
              <Route key={index} exact={route.exact} path={route.path} component={Component} />
            ))}
            <Route path="*" render={() => <Redirect to={ROUTES[0].path} />} />
          </Switch>
        </Suspense>
      </StyledLayout>
    </BrowserRouter>
  )
}

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`

export default memo(App)
